<script>
import useFurnitureStore from "../../stores/furniture";

import self from "../../../index";

const { modules } = self.app;

export default {
    name: "overall-dimensions",
    components: {},
    computed: {
        store() {
            return useFurnitureStore();
        },
        dimensions() {
            return this.store.dimensionsOverall;
        },
    },
    methods: {
        t(...args) {
            return modules.stonejs.gettext(...args);
        },
    },
};
</script>

<template>
    <div class="w-1/2 absolute bottom-0 right-0 border border-slate-300 rounded-tl-lg flex justify-around items-center bg-white py-1 px-2 text-xs">
        <div>
            <span class="font-bold">{{ t("Dimensions hors tout") }}</span>
        </div>
        <div>
            <span>{{ t("Hauteur") }}: </span>
            <span>{{ dimensions.height }}mm</span>
        </div>
        <div>
            <span>{{ t("Largeur") }}: </span>
            <span>{{ dimensions.width }}mm</span>
        </div>
        <div>
            <span>{{ t("Profondeur") }}: </span>
            <span>{{ dimensions.depth }}mm</span>
        </div>
    </div>
</template>
