<template>
    <div
        :class="[
            'transition-colors duration-300 max-w-[11rem]',
            'rounded-md bg-theme-gray-100 flex shrink-0 h-16 border-2 group ',
            'hover:border-theme-orange-500 hover:cursor-pointer ml-6 last:mr-6 relative',
            selected && 'border-theme-orange-500',
            !selected && 'border-white'
        ]"
        @click="$emit('click')"
    >
        <img v-if="imageUrl" class="rounded w-16 border-2 border-theme-gray-200 object-cover" :src="imageUrl">
        <div
            v-if="hasSVGIcon"
            :class="[
                'transition-all duration-300',
                'rounded w-16 flex items-center justify-center bg-gradient-to-br',
                !selected && 'group-hover:from-black group-hover:via-theme-pink-400 group-hover:to-theme-orange-400 group-hover:bg-pos-100',
                !selected && 'from-black via-black to-black bg-size-200 bg-pos-0',
                selected && 'from-theme-pink-400 to-theme-orange-400 bg-pos-100'
            ]"
        >
            <slot name="svgIcon" />
        </div>
        <div
            :class="[
                'flex items-center justify-center text-black text-xs p-2',
                isTextOnly ? 'w-full ' : 'max-w-[9rem]'
            ]"
        >
            <span :class="['overflow-hidden text-ellipsis max-h-12 min-w-[4rem] max-w-[8rem] whitespace-pre-line', description && 'pr-3']">
                <slot />
            </span>
        </div>
        <Tooltip v-if="description" class="absolute bottom-0 right-0">
            {{ description }}
        </Tooltip>
    </div>
</template>
<script>
import Tooltip from "./Tooltip.vue";


export default {
    components: { Tooltip },
    props: {
        selected: {
            required: true,
            type: Boolean,
        },
        disabled: {
            required: true,
            default: false,
            type: Boolean,
        },
        imageUrl: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
    },
    emits: ["click"],
    computed: {
        hasSVGIcon() {
            return !!this.$slots.svgIcon;
        },
        isTextOnly() {
            return !this.hasSVGIcon && !this.imageUrl;
        },
    },
};
</script>
