<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="21.957" height="22.146" viewBox="0 0 21.957 22.146">
        <g id="cart" transform="translate(-0.44 -0.25)">
            <path id="Tracé_143"
                  data-name="Tracé 143"
                  d="M20.446,17.349H12.39a3.267,3.267,0,0,1-3.158-2.523L7.948,9.607a3.28,3.28,0,0,1,3.21-4.057H21.749a3.28,3.28,0,0,1,3.21,4.057l-1.284,5.232A3.274,3.274,0,0,1,20.446,17.349ZM11.119,7.155a1.682,1.682,0,0,0-1.63,2.073l1.284,5.232a1.669,1.669,0,0,0,1.63,1.284h8.043a1.669,1.669,0,0,0,1.63-1.284l1.284-5.232a1.675,1.675,0,0,0-1.63-2.073Z"
                  transform="translate(-2.655 -1.898)"
                  fill="#fff"
            />
            <path id="Tracé_144"
                  data-name="Tracé 144"
                  d="M6.23,9.076a.8.8,0,0,1-.777-.642L4.3,3.447A2.061,2.061,0,0,0,2.289,1.855H1.242a.8.8,0,0,1,0-1.6H2.289A3.665,3.665,0,0,1,5.871,3.081L7.013,8.069a.8.8,0,0,1-.6.963.918.918,0,0,1-.186.045ZM9.857,22.4a2.568,2.568,0,1,1,2.568-2.568A2.568,2.568,0,0,1,9.857,22.4Zm0-3.556a.976.976,0,1,0,.976.976.976.976,0,0,0-.976-.976ZM18.728,22.4A2.568,2.568,0,1,1,21.3,19.829,2.568,2.568,0,0,1,18.728,22.4Zm0-3.556a.976.976,0,1,0,.694.283.976.976,0,0,0-.694-.283Z"
                  transform="translate(0 0)"
                  fill="#fff"
            />
            <path id="Tracé_145"
                  data-name="Tracé 145"
                  d="M21.572,26.424H10.877a2.5,2.5,0,1,1,0-4.994h1.7a.8.8,0,0,1,0,1.6h-1.7a.892.892,0,1,0,0,1.785H21.572a.8.8,0,0,1,0,1.6Z"
                  transform="translate(-2.843 -7.584)"
                  fill="#fff"
            />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "cart-icon",
});
</script>
