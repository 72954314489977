<template>
    <div class="border-t-8 border-theme-pink-400 flex">
        <TabContainer />
        <Prices />
    </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
import TabContainer from "./TabContainer.vue";
import Prices from "./Prices.vue";

export default defineComponent({
    name: "bottom-menu",
    components: {
        TabContainer,
        Prices,
    },
});
</script>
