<template>
    <div class="w-48 flex flex-col bg-black justify-center items-center">
        <div v-if="displayPrice" class="bg-black text-white flex flex-col flex-grow">
            <span class="text-sm text-center pt-2 font-bold">{{ priceLabel }} :</span>
            <span
                v-if="!isPublicPrice"
                :class="[
                    'flex-grow font-bold items-end flex justify-center relative w-fit',
                ]"
            >
                <p class="before:absolute before:left-0 before:top-5 before:right-0 before:border before:border-t before:border-white before:rotate-6">
                    {{ publicPrice }} €
                </p>
            </span>
            <span class="flex-grow font-bold items-center flex justify-center text-3xl">
                {{ price }} €
            </span>
        </div>
        <button
            :class="[
                'group bg-gradient-to-r from-theme-pink-400 to-theme-orange-400 text-white opacity-100 transition-opacity duration-300 w-full',
                'flex flex-row justify-center items-center gap-2',
                'hover:opacity-70',
                displayPrice && 'h-11',
                !displayPrice && 'h-full'
            ]"
        >
            <CartIcon v-if="displayCartIcon" />
            <span class="font-bold group-hover:opacity-100" @click="debounceAddToCart">{{ addToCartLabel }}</span>
        </button>
    </div>
</template>

<script>
import ObsidianApi from "obsidian-api";
import { defineComponent } from "@vue/runtime-core";
import self from "../../..";

import useGlobalStore from "../../stores/global";
import { debounce } from "../../../../../helpers/utils";
import CartIcon from "../icons/CartIcon.vue";

const { modules } = self.app;

export default defineComponent({
    name: "prices",
    components: {
        CartIcon,
    },
    data() {
        return {
            debounceAddToCart: debounce(() => {
                this.addToCart();
            }, 500),
        };
    },
    computed: {
        store() {
            return useGlobalStore();
        },
        isPublicPrice() {
            const obsidianConfig = ObsidianApi.$getConfig();
            return obsidianConfig.public_price;
        },
        price() {
            if (this.isPublicPrice) {
                return this.publicPrice;
            }
            return this.store.price.toFixed(2);
        },
        publicPrice() {
            return (this.store.price * 1.25).toFixed(2);
        },
        displayPrice() {
            return this.store.displayPrice;
        },
        addToCartLabel() {
            const obsidianConfig = ObsidianApi.$getConfig();
            if (obsidianConfig.public_price) {
                return "Prix pro : connectez-vous";
            }
            if (obsidianConfig.add_to_cart_label) {
                return obsidianConfig.add_to_cart_label;
            }
            return "Ajouter au panier";
        },
        displayCartIcon() {
            const obsidianConfig = ObsidianApi.$getConfig();
            return this.displayPrice && !obsidianConfig.public_price;
        },
        priceLabel() {
            const obsidianConfig = ObsidianApi.$getConfig();
            if (obsidianConfig.public_price) {
                return "Prix public HT";
            }
            return "Prix HT";
        },
    },
    methods: {
        t(...args) {
            return modules.stonejs.gettext(...args);
        },
        addToCart() {
            modules.iframeApi.sendEvent("addToCart");
        },
    },
});
</script>
