<template>
    <div
        ref="menuWrapper"
        :class="[
            'absolute left-0 top-0 h-full',
            !isCondensed && 'flex flex-col items-center justify-center',
            isCondensed && 'grid grid-rows-6 grid-flow-col'
        ]"
    >
        <MenuButton
            :class="!isCondensed && 'rounded-tr-lg'"
            :title="t('Structure extérieure')"
            :tab-name="TAB_STRUCTURE_EXT"
            :display-title="menuOpen"
            :is-condensed="isCondensed"
            @click="setActiveTab(TAB_STRUCTURE_EXT)"
        >
            <template #default="slotProps">
                <StructExtIcon :is-active="activeTab === TAB_STRUCTURE_EXT" :is-hovered="slotProps.isHovered" :title="slotProps.title" />
            </template>
        </MenuButton>
        <MenuButton
            :title="t('Structure intérieure')"
            :tab-name="TAB_STRUCTURE_INT"
            :display-title="menuOpen"
            :is-condensed="isCondensed"
            @click="setActiveTab(TAB_STRUCTURE_INT)"
        >
            <template #default="slotProps">
                <StructInterIcon :is-active="activeTab === TAB_STRUCTURE_INT" :is-hovered="slotProps.isHovered" :title="slotProps.title" />
            </template>
        </MenuButton>
        <MenuButton
            :title="t('Façade')"
            :tab-name="TAB_FRONT"
            :display-title="menuOpen"
            :is-condensed="isCondensed"
            @click="setActiveTab(TAB_FRONT)"
        >
            <template #default="slotProps">
                <FrontIcon :is-active="activeTab === TAB_FRONT" :is-hovered="slotProps.isHovered" :title="slotProps.title" />
            </template>
        </MenuButton>
        <MenuButton
            :title="t('Dos')"
            :tab-name="TAB_BACK"
            :display-title="menuOpen"
            :is-condensed="isCondensed"
            @click="setActiveTab(TAB_BACK)"
        >
            <template #default="slotProps">
                <BackIcon :is-active="activeTab === TAB_BACK" :is-hovered="slotProps.isHovered" :title="slotProps.title" />
            </template>
        </MenuButton>
        <MenuButton
            :title="t('Aménagement intérieur et Accessoires')"
            :tab-name="TAB_ACCESSORIES"
            :display-title="menuOpen"
            :is-condensed="isCondensed"
            @click="setActiveTab(TAB_ACCESSORIES)"
        >
            <template #default="slotProps">
                <AccessoriesIcon :is-active="activeTab === TAB_ACCESSORIES" :is-hovered="slotProps.isHovered" :title="slotProps.title" />
            </template>
        </MenuButton>
        <MenuButton
            :title="t('Finitions')"
            :tab-name="TAB_FINISHING_TOUCHES"
            :display-title="menuOpen"
            :is-condensed="isCondensed"
            @click="setActiveTab(TAB_FINISHING_TOUCHES)"
        >
            <template #default="slotProps">
                <FinishingsIcon :is-active="activeTab === TAB_FINISHING_TOUCHES" :is-hovered="slotProps.isHovered" :title="slotProps.title" />
            </template>
        </MenuButton>
        <div
            :class="[
                !isCondensed && 'w-full',
                isCondensed && 'fixed top-0 transition-all duration-300 ease-out w-12',
                isCondensed && menuOpen && 'left-64',
                isCondensed && !menuOpen && 'left-12'
            ]"
        >
            <button class="rounded-br-lg group bg-slate-300 hover:bg-slate-600 transition-all duration-300 text-black w-full flex justify-end items-center shadow-xl shadow-gray-300 px-4 py-2" @click="toggleMenu">
                <ChevronDoubleLeftIcon
                    :title="menuOpen ? t('Reduire le menu') : t('Agrandir le menu')"
                    :class="[
                        'transition-all duration-300 group-hover:text-slate-300',
                        !menuOpen && 'rotate-180'
                    ]"
                />
            </button>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

import useGlobalStore from "../../stores/global";
import self from "../../../index";
import {
    TAB_STRUCTURE_EXT,
    TAB_STRUCTURE_INT,
    TAB_FRONT,
    TAB_BACK,
    TAB_ACCESSORIES,
    TAB_FINISHING_TOUCHES,
} from "../../services/constants";

import MenuButton from "./MenuButton.vue";
import StructExtIcon from "../icons/StructExterIcon.vue";
import StructInterIcon from "../icons/StructInterIcon.vue";
import FrontIcon from "../icons/FrontIcon.vue";
import BackIcon from "../icons/BackIcon.vue";
import AccessoriesIcon from "../icons/AccessoriesIcon.vue";
import FinishingsIcon from "../icons/FinishingsIcon.vue";
import ChevronDoubleLeftIcon from "../icons/ChevronDoubleLeftIcon.vue";

const {
    modules,
} = self.app;

export default defineComponent({
    name: "main-menu",
    components: {
        MenuButton,
        StructExtIcon,
        StructInterIcon,
        FrontIcon,
        BackIcon,
        AccessoriesIcon,
        FinishingsIcon,
        ChevronDoubleLeftIcon,
    },
    data() {
        return {
            menuOpen: true,
            TAB_STRUCTURE_EXT,
            TAB_STRUCTURE_INT,
            TAB_FRONT,
            TAB_BACK,
            TAB_ACCESSORIES,
            TAB_FINISHING_TOUCHES,
            isCondensed: false,
        };
    },
    computed: {
        store() {
            return useGlobalStore();
        },
        activeTab() {
            return this.store.activeTab;
        },
    },
    mounted() {
        this.isCondensed = this.$refs.menuWrapper.clientHeight < 480;
    },
    methods: {
        t(...args) {
            return modules.stonejs.gettext(...args);
        },
        setActiveTab(tabName) {
            this.store.setActiveTab(tabName);
        },
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        },
    },
});
</script>
