<template>
    <Draggable
        target-type="frame"
        class="mr-4"
        :start-drag-events="startDragEvents"
        :drop-events="dropEvents"
        :end-drag-events="endDragEvents"
    >
        <div class="rounded-md bg-theme-gray-100 flex h-16 max-w-36 overflow-hidden border-2 border-white group hover:border-theme-orange-500 transition duration-300">
            <div
                :class="[
                    'transition-all duration-300',
                    'rounded w-16 flex items-center justify-center',
                    'bg-gradient-to-br from-black via-black to-black bg-size-200 bg-pos-0',
                    'group-hover:from-black group-hover:via-theme-pink-400 group-hover:to-theme-orange-400 group-hover:bg-pos-100'
                ]"
            >
                <slot />
            </div>
            <div class="flex items-center justify-center text-black min-w-[4rem] max-w-20 text-xs p-2 whitespace-pre-line">
                <slot name="text" />
            </div>
        </div>
    </Draggable>
</template>
<script>
import Draggable from "./Draggable.vue";

export default {
    components: { Draggable },
    props: {
        startDragEvents: {
            type: Array,
            default: () => [],
        },
        dropEvents: {
            type: Array,
            default: () => [],
        },
        endDragEvents: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
